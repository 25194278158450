<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/settings" label="action.back_to_settings" />
    </div>

    <div class="container">
      <h5>{{ 'pages.default.settings.security.security_settings' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div id="zone_change_password" class="{ 'mb-6': profile?.mfa_enabled }">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.settings.security.change_pw' | translate }}</h5>
        </div>
      </div>

      <form [formGroup]="passwordForm" id="form" (ngSubmit)="passwordFormSubmit()" [ngClass]="{'validation-visible': validationVisible}">
        <div class="form-group styled width-limit">
          <label for="inputCurrentPassword" class="required">{{ 'pages.default.settings.security.current_pw' | translate }}</label>
          <input type="password" id="inputCurrentPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.current_pw'|translate)" formControlName="old_password">
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.old_password?.errors">{{ 'form.feedback.current_pw_incorrect' | translate }}</p>
        </div>

        <div class="form-group styled width-limit">
          <label for="inputNewPassword" class="required">{{ 'pages.default.settings.security.new_pw' | translate }}</label>
          <input type="password" id="inputNewPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.new_pw'|translate)" formControlName="new_password" [ngClass]="{'ng-invalid-important': (passwordForm?.errors?.mismatch)}">
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.new_password?.errors?.password_policy">{{ 'form.feedback.pw_complexity_error' | translate }}</p>
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.new_password?.errors?.password_already_used">{{ 'form.feedback.pw_already_used' | translate:{value: policy?.history_size} }}</p>
        </div>

        <div class="form-group styled width-limit">
          <label for="inputConfirmNewPassword" class="required">{{ 'pages.default.settings.security.confirm_pw' | translate }}</label>
          <input type="password" id="inputConfirmNewPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.confirm_pw'|translate)" formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': passwordForm?.errors?.mismatch}">
          <p class="validation-feedback general-feedback" *ngIf="passwordForm?.errors?.mismatch">{{ 'pages.default.settings.security.pw_not_the_same' | translate }}</p>
        </div>

        <button type="submit" class="btn btn-primary mt-3 mb-6" [ngClass]="{'loader': isSettingPassword}">
          <span class="loader" *ngIf="isSettingPassword" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'pages.default.settings.security.change_pw' | translate }}</span>
        </button>
      </form>

      <app-password-policy-rules
        [policy]="policy"
        [validationVisible]="validationVisible"
        [passwordControl]="passwordForm?.controls?.new_password"
      />
    </div>

    <hr class="my-6" *ngIf="profile?.mfa_enabled" />

    <div id="zone_tsa" *ngIf="profile?.mfa_enabled">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.settings.security.two_step_auth' | translate }}</h5>
        </div>
      </div>

      <p class="caption">
        {{ 'pages.default.settings.security.mfa_primary_method' | translate }}
      </p>

      <div class="single-item-block mb-2" *ngFor="let mfaMethode of profile?.mfaPrimaryMethods">
        <div class="block-icon">
          <span *ngIf="mfaMethode?.channel === 'EMAIL'" [inlineSVG]="'/assets/svg/envelope-outline-24.svg'" class="icon rtl-mirrored-inline-svg"></span>
          <span *ngIf="mfaMethode?.channel === 'SMS'" [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </div>
        <div class="block-wrapper">
          <p class="label" *ngIf="mfaMethode?.channel === 'EMAIL'">{{ 'pages.default.settings.security.label.email' | translate }}</p>
          <p class="value"  *ngIf="mfaMethode?.channel === 'EMAIL'">{{profile?.email}}</p>
          <p class="label" *ngIf="mfaMethode?.channel === 'SMS'">{{ 'pages.default.settings.security.label.phone' | translate }}</p>
          <p class="value" *ngIf="mfaMethode?.channel === 'SMS'">{{ currentDialCode?.label }} {{ profile?.mobile_number?.number }}</p>
        </div>
        <div class="block-wrapper m-start-auto m-end-1">
          <p>{{ 'pages.default.settings.security.mfa_primary_method_cant_be_removed' | translate }}</p>
        </div>
      </div>

      <p class="caption mt-4">
        {{ 'pages.default.settings.security.mfa_backup_method' | translate }}
      </p>

      <ng-container *ngIf="profile?.mfaBackupMethods?.length">
        <div class="single-item-block mb-2" *ngFor="let mfaMethode of profile?.mfaBackupMethods">
          <div class="block-icon">
            <span *ngIf="mfaMethode?.channel === 'EMAIL'" [inlineSVG]="'/assets/svg/envelope-outline-24.svg'" class="icon rtl-mirrored-inline-svg"></span>
            <span *ngIf="mfaMethode?.channel === 'SMS'" [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'" class="icon rtl-mirrored-inline-svg"></span>
          </div>
          <div class="block-wrapper">
            <p class="label" *ngIf="mfaMethode?.channel === 'EMAIL'">{{ 'pages.default.settings.security.label.email' | translate }}</p>
            <p class="value" *ngIf="mfaMethode?.channel === 'EMAIL'">{{profile?.email}}</p>
            <p class="label" *ngIf="mfaMethode?.channel === 'SMS'">{{ 'pages.default.settings.security.label.phone' | translate }}</p>
            <p class="value" *ngIf="mfaMethode?.channel === 'SMS'">{{ currentDialCode?.label }} {{ profile?.mobile_number?.number }}</p>
          </div>
          <div class="block-wrapper" *ngIf="!mfaMethode.canBeRemoved">
            <p>{{ 'pages.default.settings.security.mfa_this_method_cant_be_removed' | translate }}</p>
          </div>
          <div class="block-btn-wrapper m-end-2 gap-2">
            <a href="" class="btn btn-secondary" *ngIf="mfaMethode.canBeRemoved" (click)="removeMfaBackupMethod($event, mfaMethode)">
              {{ 'pages.default.settings.security.mfa_remove' | translate }}
            </a>
            <a href="" class="btn btn-primary" (click)="setPrimaryMfaMethod($event, mfaMethode)">
              {{ 'pages.default.settings.security.mfa_make_primary' | translate }}
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!profile?.mfaBackupMethods?.length">
        <p class="text-muted">{{ 'pages.default.settings.security.mfa_no_backup_set_up' | translate }}</p>
      </ng-container>

      <button class="btn btn-primary" (click)="showAddMfaBackupModal($event)" *ngIf="profile?.mfaBackupMethods?.length < 1">
        {{ 'pages.default.settings.security.add_backup_method' | translate }}
      </button>
    </div>

    <!--
    <div id="zone_trusted_devices" class="mb-5 d-none">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.security.trusted_devices' | translate }}</h2>
          <p>{{ 'pages.default.settings.security.trusted_devices_text' | translate }}</p>
        </div>
      </div>
    </div>
    -->

  </div>
</div>
