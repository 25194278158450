<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close-24.svg'"></span>
  </button>

  <h2>{{ 'modals.delete_account.delete_account_personal_data' | translate }}</h2>
  <p>{{ 'modals.delete_account.delete_account_info' | translate }} <a href="mailto:support@care4todaypatient.com" class="text-secondary">{{ 'modals.delete_account.support_email' | translate }}</a>.</p>
  <p>{{ 'modals.delete_account.provide_pw_confirm' | translate }}</p>

  <form [formGroup]="form" id="form" class="form-width-limit mt-5" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label">
      <input type="password" id="inputPassword" class="form-control w-100" [placeholder]="('form.labels.your_pw' | translate)" formControlName="password">
      <label for="inputPassword">{{ 'form.labels.your_pw' | translate }}</label>
       <!-- in case of local error feedback concerning the password field -->
      <p class="validation-feedback" *ngIf="form?.controls['password']?.errors">{{ 'form.feedback.pw_invalid' | translate }}</p>
      <!-- in case of server error feedback concerning the password -->
      <p class="validation-feedback general-feedback" *ngIf="isWrongPassword">{{ 'form.feedback.pw_invalid' | translate }}</p>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" (click)="handleDelete()" [ngClass]="{'loader': isRequesting}">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isRequesting"></em></span>
      <span>{{ 'modals.delete_account.yes_delete_account_personal_data' | translate }}</span>
    </button>
  </div>
</div>
