<div class="mb-2 mb-xl-3">
  <app-select
    class="d-inline-block w-auto"
    [selectClass]="'text-select ng-select-sm m-end-2 ff-overflow-scroll'"
    id="select_flow_copyrights_language"
    [searchable]="false"
    [multiple]="false"
    [items]="languageOptions"
    [(ngModel)]="selectedLanguage"
    [bindValue]="'value'"
    (change)="onLanguageSelection()"

    [autoSortOnLabel]="true"
    [selectedItemsOnTop]="false"

    *ngIf="languageSelect"
  ></app-select>

  <div class="mt-sm-2" *ngIf="cookiesButton">
    <a href="" id="button_flow_copyrights_cookie_settings" class="btn btn-sm btn-as-text m-end-1" (click)="triggerCookies($event)">
      <span>{{ 'components.flow_copyrights.cookie_settings' | translate }}</span>
      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-16.svg'"></span>
    </a>

    <a href="" id="button_flow_copyrights_cookie_page" class="btn btn-sm btn-as-text" (click)="goToCookiePolicy($event)">
      <span>{{ 'components.flow_copyrights.cookie_page' | translate }}</span>
    </a>
  </div>
</div>

<div class="row copyrights-info align-items-center">
  <div class="col-sm-auto">
    <span class="logo" [inlineSVG]="'/assets/svg/logo-jj-medtech.svg'"></span>
  </div>
  <div class="col-sm-auto m-start-sm-auto mt-1 mt-sm-0">
    <p class="m-0 small text-muted">&copy; {{ thisYear }} {{ 'general.copyright' | translate }}</p>
  </div>
</div>
