<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.settings.overview.settings' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div routerLink="/settings/personal"
        class="single-item-block mb-2 item-clickable"
        id="settingsPersonalBtn">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'/assets/svg/account-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.personal_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.personal_info_contact_details' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-personal-information-arrow"></span>
        </div>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <div routerLink="/settings/preferences"
        class="single-item-block mb-2 item-clickable"
        id="settingsPreferencesBtn">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'/assets/svg/filter-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.preferences' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.preferences_info' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-preferences-arrow"></span>
        </div>
      </div>
    </div>

    <div routerLink="/settings/security"
        class="single-item-block mb-2 item-clickable"
        id="settingsSecurityBtn">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'/assets/svg/locked-outline-16.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.security_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.security_settings_info' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-security-arrow"></span>
        </div>
      </div>
    </div>

    <!-- temp hide this block -->
    <div routerLink="/settings/privacy"
        class="single-item-block mb-2 item-clickable d-none"
        id="settingsPrivacyBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <!-- <span class="icon" [inlineSVG]="'/assets/svg/XXX-NEEDS-NEW-ICON.svg'"></span> -->
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.privacy_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.privacy_settings_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
      </div>
    </div>

    <!-- temp hide this block -->
    <div routerLink="/settings"
         class="single-item-block mb-2 item-clickable d-none"
         id="settingsExternalAppsBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <!-- <span class="icon" [inlineSVG]="'/assets/svg/XXX-NEEDS-NEW-ICON.svg'"></span> -->
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.external_apps' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.external_apps_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
      </div>
    </div>

    <div routerLink="/settings/consents"
        class="single-item-block mb-2 item-clickable"
        id="settingsConsentsBtn">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'/assets/svg/privacy-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.consents' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.view_agreements' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-privacy-legal-arrow"></span>
        </div>
      </div>
    </div>
  </div>
</div>
