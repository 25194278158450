import {FormFieldValueType} from '../enums/form-field-value-type';
import {GeneralService} from '../services/general.service';

export type EnumValue = {
  region: string,
  key: string,
  order: number,
  value: string,
  label: string,
  translationKey: string
};

export class FormFieldValue {
  public generalService: GeneralService;

  value: any;
  value_type: FormFieldValueType;
  enum_values: Array<EnumValue>;
  min_length: number;
  max_length: number;
  min: number;
  max: number;
  multi_select_allowed: boolean;
  multi_select_max: number;
  multi_select_min: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item?.values?.length && item.multi_select_allowed) {
      this.value = item.values;
    } else if (item?.values?.length === 1 && !item.multi_select_allowed) {
      this.value = item.values[0];
    } else {
    this.value = item.value;
    }

    this.value_type = item.value_type;
    this.multi_select_allowed = item.multi_select_allowed;
    this.multi_select_max = item.multi_select_max;
    this.multi_select_min = item.multi_select_min;

    if (item.enum_values) {
      this.enum_values = this.mapEnumValues(item.enum_values);
    }

    if (item.min_length) {
      this.min_length = item.min_length;
    }

    if (item.max_length) {
      this.max_length = item.max_length;
    }

    if (item.min) {
      this.min = item.min;
    }

    if (item.max) {
      this.max = item.max;
    }
  }

  mapEnumValues(input: Record<string, EnumValue>): Array<EnumValue> {
    return Object.entries(input).map(([value, details]) => ({
      value,
      label: `shared.${details.region}.${details.key}`,
      translationKey: `shared.${details.region}.${details.key}`,
      ...details,
    }));
  }
}
