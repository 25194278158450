<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': validationVisible}">
      <p>
        <a id="link_forgot_password_back" [routerLink]="['/']" class="text-icon-link text-secondary">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/arrow-left-24.svg'"></span>
          <span>{{ 'pages.flow.forgot_password.back_to_home' | translate }}</span>
        </a>
      </p>

      <h6>{{ 'pages.flow.forgot_password.forgot_pw' | translate }}</h6>

      <p class="small mb-3">{{ 'pages.flow.forgot_password.forgot_pw_text' | translate }}</p>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" class="form-group" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
        <label for="inputEmail">{{ 'form.labels.email_address' | translate }}</label>
        <input type="email" id="inputEmail" class="form-control w-100" formControlName="email" >
        <p class="validation-feedback" *ngIf="form?.controls['email']?.errors?.email || form?.controls['email']?.errors?.required">{{ 'pages.flow.forgot_password.email_invalid' | translate }}</p>
      </form>

      <button id="button_forgot_password_submit" type="submit" class="btn btn-primary mt-2 mt-lg-3" [ngClass]="{'loader': isLoading}" (click)="formSubmit()">
        <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
        <span>{{ 'pages.flow.forgot_password.send_instructions' | translate }}</span>
      </button>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background with-background d-flex flex-column">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="body-content mt-auto m-start-auto">
    <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
      <span class="icon" [inlineSVG]="'/assets/svg/assistant-24.svg'"></span>
      <span>{{ 'pages.flow.home.need_help' | translate }}</span>
    </button>
  </div>

  <div class="layout-background"></div>
</div>
