<div class="dynamic-input"
     [ngClass]="{'h-auto': field.ui_config?.ui_style === uiStyleOption.PICKER_WHEEL || field.ui_config?.ui_style === uiStyleOption.DROPDOWN}">

  <!-- TYPE Boolean Checkbox -->
  <div class="form-group"
       *ngIf="field?.field_value.value_type === formFieldValueType.BOOLEAN && !field.required">

    <div class="form-check m-0">
      <input type="checkbox" class="form-check-input" [id]="field.id" [formControl]="formControl">
      <label class="form-check-label" [for]="field.id">{{ field?.translationKey | translate | evalTranslation }}</label>

      <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"/>
    </div>
  </div>

  <!-- TYPE Boolean Radiobutton -->
  <div class="form-group"
       *ngIf="field?.field_value.value_type === formFieldValueType.BOOLEAN && field.required">
    <label
      [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">{{ field?.translationKey | translate | evalTranslation }}</label>

    <div class="form-checks-inline">
      <div class="form-check">
        <input class="form-check-input" [id]="'yes_'+field.id" type="radio" [formControl]="formControl"
               [value]="true">
        <label class="form-check-label" [for]="'yes_'+field.id">{{ 'action.yes' | translate }}</label>
      </div>

      <div class="form-check">
        <input class="form-check-input" [id]="'no_'+field.id" type="radio" [formControl]="formControl"
               [value]="false">
        <label class="form-check-label" [for]="'no_'+field.id">{{ 'action.no' | translate }}</label>
      </div>

      <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0"/>
    </div>
  </div>

  <!-- TYPE ENUM -->
  <!-- TYPE ENUM -->
  <!-- TYPE ENUM -->
  @if (field?.field_value.value_type === formFieldValueType.ENUM) {
    @switch (field.ui_config?.ui_style) {
      @case (uiStyleOption.RADIO) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.TILE) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.YESNO) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.CHECKBOX) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.DROPDOWN) {
        <div class="ui-config enum-dropdown">

          <div class="labels">
            <h6 class="d-block mb-0">{{ field?.translationKey | translate }}</h6>
            <p class="mb-0 optional small text-muted" *ngIf="!field.required">
              ({{ 'component.dynamic_input.optional' | translate }})
            </p>
          </div>
          <div class="form-group">
            <app-select
              class="enum-select"
              [label]="('action.select_one' | translate)"
              [clearable]="false"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="field?.field_value.enum_values | sortBy: 'asc': 'order'"
              [bindValue]="'value'"
              [bindLabel]="'label'"
              [autoSortOnLabel]="false"
              [selectedItemsOnTop]="false" [formControl]="formControl" [asFilter]="false"
              [showCheckboxes]="false"
              [ngClass]="{'required' : field.required}"/>

            <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
          </div>
        </div>
      }
      @default {
        <div class="form-group">
          <label [ngClass]="{'required' : field.required, 'invalid': !formControl?.valid}">
            {{ field?.translationKey | translate }}
          </label>

          <div [ngClass]="field.field_value.enum_values?.length > 2 ? 'form-checks mt-1': 'form-checks-inline'">
            <div class="form-check" *ngFor="let enum of field?.field_value.enum_values | sortBy: 'asc': 'order';">
              <input class="form-check-input" type="radio" [id]="field.id + enum.value" [formControl]="formControl"
                     [name]="field.id" [value]="enum.value">
              <label class="form-check-label" [for]="field.id + enum.value">{{ enum.label | translate }}</label>
            </div>

            <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
          </div>

          @if (field.description) {
            <p class="text-muted small">
              {{ field?.description | translateHelp | translate }}
            </p>
          }
        </div>
      }
    }
  }
  <!-- END TYPE ENUM -->
  <!-- END TYPE ENUM -->
  <!-- END TYPE ENUM -->

  <!-- TYPE NUMBER -->
  <!-- TYPE NUMBER -->
  <!-- TYPE NUMBER -->
  @if (field?.field_value.value_type === formFieldValueType.NUMBER) {
    @switch (field.ui_config?.ui_style) {
      @case (uiStyleOption.SLIDER_HORIZONTAL) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.SLIDER_VERTICAL) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.PICKER_WHEEL) {
        <div
          class="ui-config form-group styled no-infield-label styled-question style-{{field?.ui_config?.ui_style}}">
          <div class="labels">
            <h6 class="mb-0">{{ field?.translationKey | translate }}</h6>
            <p class="mb-0 optional small text-muted" *ngIf="!field.required">
              ({{ 'component.dynamic_input.optional' | translate }})
            </p>
          </div>

          <div class="picker-wheel-wrapper">
            <div class="picker-wheel">
              @if (field.ui_config?.measuring_unit) {
                <p class="picker-wheel-unit mb-0">
                  {{ 'component.dynamic_input.picker_wheel.measuring_unit.' + field.ui_config?.measuring_unit?.toLowerCase() | translate }}
                </p>
              }

              <input class="form-control picker-wheel-input" type="number" (keydown)="numberOnly($event)"
                     [name]="field.id" [formControl]="formControl" [id]="'field_'+field.id" [value]="value"
                     [step]="field?.ui_config?.number_steps_interval?.sub ?? 1">
            </div>

            <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
          </div>
        </div>
      }
      @default {
        <div class="form-group">
          <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}" [for]="'field_'+field.id">
            {{ field?.translationKey | translate | evalTranslation }}
          </label>
          <input placeholder="" class="form-control" type="number" [name]="field.id" [formControl]="formControl"
                 [id]="'field_'+field.id">

        <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
        </div>
      }
    }
  }
  <!-- END TYPE NUMBER -->
  <!-- END TYPE NUMBER -->
  <!-- END TYPE NUMBER -->

  <!-- TYPE Decimal -->
  <div class="form-group"
       *ngIf="field?.field_value.value_type === formFieldValueType.DECIMAL_NUMBER">
    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}" [for]="'field_'+field.id">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <input placeholder="" class="form-control" type="number"
           [name]="field.id" [formControl]="formControl" [id]="'field_'+field.id">

    <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
  </div>

  <!-- TYPE String -->
  <div class="form-group" *ngIf="field?.field_value.value_type === formFieldValueType.STRING">
    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <input placeholder="" class="form-control" type="text" [name]="field.id" [formControl]="formControl">

    <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
  </div>

  <!-- TYPE Date -->
  <div class="form-group form-group-calendar"
       *ngIf="field?.field_value.value_type === formFieldValueType.DATE">
    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <app-date-input [formControl]="formControl" [name]="field.id"/>

    <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
  </div>

  <!-- TYPE Date-Time -->
  <div class="form-group" *ngIf="field?.field_value.value_type === formFieldValueType.DATE_TIME">
    <label class="small" [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <app-date-time-input [formControl]="formControl" [name]="field.id"/>

    <app-feedback-field [field]="formControl" [meta]="field" extraClass="mb-0" />
  </div>

  <!-- Description for  Number,  Boolean, String and Date -->
  @if (field?.field_value.value_type !== formFieldValueType.ENUM && field.description) {
    <p class="text-muted small mt-1">
      {{ field?.description | translateHelp | translate }}
    </p>
  }
</div>
