<div class="redirect-layout">
  <div class="layout-header with-background">
    <div class="container">
      <div class="text-center py-7">
        <h4 class="text-white mb-0 display-2">{{ 'pages.onboarding.redirect_to_app.account_created' | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="layout-body">
    <div class="content-part">
      <div class="container">
        <p>{{ 'pages.onboarding.redirect_to_app.start_using_app' | translate }}</p>

        <h6>{{ 'pages.onboarding.redirect_to_app.download_app' | translate }}</h6>
        <button class="btn p-0" (click)="redirectToStore()">
          <span class="icon store" [inlineSVG]="storeUrl"></span>
        </button>

        <hr />


        <div class="info-box">
          <em class="info-box-icon" [inlineSVG]="'/assets/svg/info-circle-24.svg'"></em>
          <h6 class="info-box-title">{{ 'pages.onboarding.redirect_to_app.did_you_know' | translate }}</h6>

          <p>
            {{ 'pages.onboarding.redirect_to_app.visit_web_portal_at' | translate }} <strong>{{
              'pages.onboarding.redirect_to_app.portal_link' | translate }}</strong> {{
            'pages.onboarding.redirect_to_app.and_login' | translate }}
          </p>

          <p class="small text-muted">
            {{ 'pages.onboarding.redirect_to_app.ensure_best_experience' | translate }}
          </p>
        </div>

        <div class="footer-part mt-6">
          <app-flow-copyrights></app-flow-copyrights>
        </div>

      </div>
    </div>
  </div>
</div>
