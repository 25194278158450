<audio #audioElement >
  <source [src]="source" type='audio/mp3' *ngIf="source && source !== ''" />
</audio>

<div [ngClass]="['audio-player', inputClass, (timeStamp ? 'has-timestamp' : 'no-timestamp')]">
  <button (click)="play()" id="audio_player_btn_play" class="btn" [hidden]="playing"> <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/play-outline-32.svg'"></span></button>
  <button (click)="pause()" id="audio_player_btn_pause" class="btn" [hidden]="!playing"><span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/pause-outline-32.svg'"></span></button>

  <div class="progress-label" id="audio_player_progress">
    {{ progressTime * 1000 | date:'mm:ss' }}
  </div>

  <div class="wave" id="audio_player_wave">
    <input type="range"  min="0" max="100" [value]="progressPercent" (change)="stopSeek($event)" (mousedown)="startSeek($event)" (input)="seek($event.target.value)"/>
    <div class="background h-100">
      <div class="progress" [style.width.%]="progressPercent"></div>
    </div>
  </div>

  <div class="timestamp" *ngIf="timeStamp">{{ timeStamp }}</div>
</div>
