<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/settings/consents" label="pages.default.settings.consent_detail.back_to_consents" />
    </div>

    <div class="container">
      <h5>{{ consent?.title }}</h5>
    </div>
  </div>
</div>

<div class="body-content full-height">
  <div class="container">
    <div class="text-content-max">
      <h6 class="mb-5">{{ consent?.introduction }}</h6>
      <div [innerHtml]="consent?.body"></div>
    </div>
  </div>
</div>

<div class="layout-footer-pusher footer-actions"></div>
<div class="layout-footer footer-actions">
  <div class="footer-content">
    <div class="container">
      <div class="m-start-auto">
        <button *ngIf="consent?.isConsented()" class="btn btn-primary btn-lg" (click)="doDeconsent()" [ngClass]="{'loader': isPerformingAction}">
          <span class="loader" *ngIf="isPerformingAction" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.i_do_not_consent' | translate }}</span>
        </button>
        <button *ngIf="!consent?.isConsented()"  class="btn btn-primary btn-lg" (click)="doConsent()" [ngClass]="{'loader': isPerformingAction}">
          <span class="loader" *ngIf="isPerformingAction" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.i_consent' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
