<div class="bg-pattern-light">
  <div class="container">
    <div class="layout-sidebar">
      <div class="sidebar-content is-flexed responsive-sidebar">
        <div class="content-part">
          <div class="sidebar-logo d-none d-sm-block" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>
          <div class="icon icon-lg d-block d-sm-none pb-4" [inlineSVG]="'/assets/svg-color/polyphonic-hexagon.svg'"></div>
        </div>

        <div class="content-part d-block d-sm-none">
          <div class="mb-4">
            <h5 class="m-0">{{ 'components.flow_welcome.welcome_to' | translate }}</h5>
            <h4 class="m-0">
              <span>Polyphonic</span>
              {{ 'components.flow_welcome.care' | translate }}
            </h4>
          </div>
        </div>

        <div class="content-part" [ngClass]="{'validation-visible': showFieldValidation()}">

          <h6>{{ 'pages.flow.self_onboarding_code.enter_code_hcp' | translate }}</h6>

          <div class="form-group">
            <label for="inputCode">{{ 'pages.flow.self_onboarding_code.enter_code' | translate }}</label>
            <input type="text" id="inputCode" class="form-control w-100"
              [ngClass]="{'ng-invalid-important': showFieldValidation()}" [(ngModel)]="codeInput" autocomplete="off">
            <p class="validation-feedback general-feedback" *ngIf="codeIncorrect">{{
              'pages.flow.self_onboarding_code.code_incorrect' | translate }}</p>
            <p class="validation-feedback general-feedback" *ngIf="codeEmpty && !codeInput">{{
              'pages.flow.self_onboarding_code.enter_hc' | translate }}</p>
          </div>

          <p class="mt-1 small mb-0 text-muted">{{ 'pages.flow.self_onboarding_code.dont_have_codes' | translate }}<br />{{
            'pages.flow.self_onboarding_code.dont_have_codes_text' | translate }}</p>

          <button id="button_onboarding_code_submit" type="submit" class="btn btn-primary mt-3 mt-lg-4"
            [ngClass]="{'loader': isLoading}" (click)="submit()">
            <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
            <span>{{ 'action.continue' | translate }}</span>
          </button>
        </div>

        <div class="footer-part mt-auto d-flex d-sm-block">
          <app-flow-copyrights></app-flow-copyrights>

          <button class="btn btn-secondary align-self-center btn-icon m-start-auto d-sm-none" (click)="openHelpCenter()">
            <span class="icon" [inlineSVG]="'/assets/svg/assistant-24.svg'"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="layout-body fixed with-background d-none d-sm-flex flex-column">
      <div class="body-content">
        <app-flow-welcome></app-flow-welcome>
      </div>

      <div class="body-content mt-auto m-start-auto">
        <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
          <span class="icon" [inlineSVG]="'/assets/svg/assistant-24.svg'"></span>
          {{ 'pages.flow.home.need_help' | translate }}
        </button>
      </div>

      <div class="layout-background"></div>
    </div>
  </div>
</div>
