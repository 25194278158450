<div class="reminder-item-block block-compact item-clickable" [ngClass]="{
    'block-danger' : (reminder?.className === 'UserTaskBasic' && reminder?.passed())
  }" (click)="openReminder($event)">
  <div class="block-icon icon-only">

    <ng-container [ngSwitch]="reminder?.className">

      <!-- Icon(s) for 'Reminder' typed reminders -->
      <ng-container *ngSwitchCase="'Reminder'">
        <ng-container [ngSwitch]="reminder?.type">
          <span class="icon" [inlineSVG]="'/assets/svg/learning-outline-24.svg'" *ngSwitchCase="'UNREAD_MATERIALS'"></span>
          <span class="icon" [inlineSVG]="'/assets/svg/calendar-outline-24.svg'" *ngSwitchCase="'APPOINTMENT'"></span>
          <span class="icon" [inlineSVG]="'/assets/svg/checklist-24.svg'" *ngSwitchDefault></span>
        </ng-container>
      </ng-container>

      <!-- Icon(s) for 'UserTaskBasic' typed reminders -->
<!--      <ng-container *ngSwitchCase="'UserTaskBasic'">-->
<!--        <span class="icon" [inlineSVG]="'/assets/svg/checklist-24.svg'"></span>-->
<!--      </ng-container>-->

      <!-- Icon(s) for 'PathwayMessage' typed reminders -->
<!--      <ng-container *ngSwitchCase="'PathwayMessage'">-->
<!--        <span class="icon" [inlineSVG]="'/assets/svg/checklist-24.svg'"></span>-->
<!--      </ng-container>-->

      <!-- Icon(s) when all other cases fail to match -->
      <ng-container *ngSwitchDefault>
        <span class="icon" [inlineSVG]="'/assets/svg/checklist-24.svg'"></span>
      </ng-container>

    </ng-container>
  </div>

  <div class="block-wrapper m-end-auto">
    <ng-container [ngSwitch]="reminder?.className">

      <!-- Label for 'Reminder' typed reminders -->
      <ng-container *ngSwitchCase="'Reminder'">
        <ng-container [ngSwitch]="reminder?.type">

          <p class="label py-1" *ngSwitchCase="'UNREAD_MATERIALS'">
            {{ 'pages.default.dashboard.reminders.learning_materials' | translate }}
          </p>

          <p class="label py-1" *ngSwitchCase="'APPOINTMENT'">
            <span *ngIf="reminder.title_key">{{ reminder.title_key | translateHelp | translate }}</span>
            <span *ngIf="!reminder.title_key">{{ reminder.title }}</span>

            <ng-container *ngIf="reminder?.start_date">
              {{ 'pages.default.dashboard.reminders.on' | translate }}
              {{ reminder?.start_date | timeZoneDate }}
              {{ 'pages.default.dashboard.reminders.at' | translate }}
              <span>{{ reminder?.start_date | time }}</span>
            </ng-container>

            <ng-container *ngIf="reminder?.date">
              {{ 'pages.default.dashboard.reminders.on' | translate }}
              {{ reminder?.date | timeZoneDate }}
            </ng-container>
          </p>

        </ng-container>
      </ng-container>

      <!-- Label for 'UserTaskBasic' typed reminders -->
      <ng-container *ngSwitchCase="'UserTaskBasic'">
        <p class="label py-1">{{ reminder?.translationKey | translate }}</p>
      </ng-container>

      <!-- Label for 'PathwayMessage' typed reminders -->
      <ng-container *ngSwitchCase="'PathwayMessage'">
        <p class="label py-1">{{ reminder?.translationKey | translate }}</p>
      </ng-container>


    </ng-container>
  </div>

  <div class="block-wrapper">
    <p class="text-info" *ngIf="reminder?.className === 'PathwayMessage' && !reminder?.read_at">
      <span class="icon sm" [inlineSVG]="'/assets/svg/dot-24.svg'"></span>
      <span class="align-middle">{{ 'components.patient_reminder_item.new' | translate }}</span>
    </p>
  </div>

  <div class="block-btn-wrapper m-0">
    <div class="block-action btn btn-lg btn-tertiary btn-icon">
      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-32.svg'" *ngIf="!isEvaluating"></span>
      <em class="spinner-border spinner-border-sm" *ngIf="isEvaluating"></em>
    </div>
  </div>
</div>
