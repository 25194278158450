<div class="layout-sidebar container">
  <div class="sidebar-content is-flexed">
    <div class="content-part d-none d-sm-block">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small">{{ 'action.create_acc' | translate }}</p>
      <app-onboaring-icon-steps [onBoardingType]="onBoardingType" [currentState]="currentState" [consents]="allConsents" [currentConsent]="currentConsent"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <div class="text-content-max">
        <h3 class="mb-3">{{ currentConsent?.title }}</h3>
        <h6 class="mb-5">{{ currentConsent?.introduction }}</h6>
        <div [innerHtml]="currentConsent?.body"></div>
      </div>
    </div>

    <div class="py-3 d-sm-none"></div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="w-100 d-flex flex-column-reverse flex-xs-row justify-content-xs-end">
          <button id="button_onboarding_consents_revoke" class="btn btn-lg btn-secondary justify-content-center"
            *ngIf="canRevokeConsent()" (click)="showRevokeConsentModal()">
              {{ 'action.i_do_not_consent' | translate}}
            </button>
          <button id="button_onboarding_consents_skip" class="btn btn-lg btn-secondary justify-content-center m-start-xs-3"
            *ngIf="canSkipConsent()" (click)="handleSkipConsent()" [ngClass]="{'loader': isLoadingSkip}">
            <span class="loader" *ngIf="isLoadingSkip" [inlineSVG]="'/assets/svg/loader-light-sm.svg'"></span>
            <span>{{ 'pages.flow.onboarding_consent.maybe_later' | translate }}</span>
          </button>
          <button id="button_onboarding_consents_consent" class="btn btn-lg btn-primary justify-content-center m-start-xs-3 mb-1 mb-xs-0"
            *ngIf="currentConsent" (click)="handleDoConsent()" [ngClass]="{'loader': isLoadingConsent}">
            <span class="loader" *ngIf="isLoadingConsent" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
            <span>{{ 'action.i_consent' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
