<div class="modal-header user-task-visual-modal">
  @if (showBackBtn) {
    <button type="button" class="btn btn-icon btn-tertiary my-auto" id="user-task-visual-modal-back" aria-label="Back" (click)="stepBack()">
      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-left-24.svg'"></span>
    </button>
  }

  <div class="ut-header">
    @if (modalTitle) {
      <p class="small mb-0 font-weight-bold text-uppercase text-center text-truncate">
        {{ modalTitle | translate }}
      </p>
    }

    @if (modalTitle && showProgressBar) {
      <div class="pb-2"></div>
    }

    @if (showProgressBar) {
      <div class="w-100 mx-auto">
        <div class="progress">
          <div class="progress-bar" role="progressbar" [style.width]="percentage"></div>
        </div>
      </div>
    }
  </div>

  <button type="button" class="btn-close mt-auto mb-auto" id="user-task-visual-modal-close m-start-0" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body user-task-visual-modal">
  <form [formGroup]="form" class="h-100 mx-5"
        [ngClass]="{'is-evaluating': isEvaluating}">

    @if (isIntroStep) {
      <div class="wizard-step is-intro">
        <h6 class="overflow-hidden">{{ userTask.translationTitleKey | translate }}</h6>
        <p>{{ currentStep['intro'] | translate }}</p>
      </div>
    } @else {
      <!-- WIZARD -->
      @if (ui_config?.wizard) {
        <div class="wizard-step wizard-type-{{ui_config?.wizard}} h-100 pb-5">

          @if (currentStep['form_fields']?.length) {
            <!-- Section -->
            @for (form_field of currentStep['form_fields']; track form_field) {
              <div class="mb-4" [ngClass]="{'validation-visible': validationVisible(form?.get(form_field.id))}">
                <app-dynamic-input class="wizard-section-item" id="section-{{ form_field.id}}" [field]="form_field"
                                   [formControl]="form.get(form_field.id)"/>
              </div>
            }
          } @else {
            <!-- Single question -->
            <div class="h-100" [ngClass]="{'validation-visible': validationVisible(form?.get(currentStep['id']))}">
              <app-dynamic-input id="no-section-{{ currentStep['id']}}" [field]="currentStep"
                                 [formControl]="form.get(currentStep['id'])"/>
            </div>
          }
        </div>
        <!-- END WIZARD -->
      } @else {
        <!-- NO WIZARD -->
        <div class=" wizard-step wizard-type-none h-100 pb-5
              ">
          @for (form_field of currentStep['form_fields']; track form_field) {
            @if (form_field.visible) {
              <div class="mb-3" [ngClass]="{'validation-visible': validationVisible(form?.get(form_field.id))}">
                <app-dynamic-input class="wizard-section-item" id="no-section-{{ form_field.id}}" [field]="form_field"
                                   [formControl]="form.get(form_field.id)"/>
              </div>
            }
          }

          @for (section of currentStep['sections']; track section) {
            @if (section.visible) {
              <div class="section mb-4">
                <h5>{{ section.translationKey | translate }}</h5>

                @for (form_field of section.form_fields; track form_field) {
                  @if (form_field.visible) {
                    <div class="mb-3" [ngClass]="{'validation-visible': validationVisible(form?.get(form_field.id))}">
                      <app-dynamic-input class="wizard-section-item" id="section-{{ form_field.id}}"
                                         [field]="form_field"
                                         [formControl]="form.get(form_field.id)"></app-dynamic-input>
                    </div>
                  }
                }
              </div>
            }
          }
        </div>
        <!-- END NO WIZARD -->
      }
    }

  </form>
</div>

<div class="modal-footer user-task-visual-modal">
  <div class="m-auto">
    @if (currentIndex + 1 < steps?.length) {
      <button class="btn btn-primary" id="visual-questionnaire-step-forward"
              [ngClass]="{'loader': isEvaluating}" (click)="stepForward()"
              [disabled]="!canProceed || isEvaluating">
        <span class="loader" *ngIf="isEvaluating" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'" ></span>
        <span>
          @if(currentStep.hasOwnProperty('intro_button')) {
            {{ currentStep['intro_button'] | translate }}
          }
          @else {
            {{ 'action.next_question' | translate}}
          }
      </span>
      </button>
    } @else {
      <button class="btn btn-primary" id="visual-questionnaire-submit" [ngClass]="{'loader': isSubmitting || isEvaluating}"
              (click)="handleSubmit()" [disabled]="form?.invalid || form?.disabled || isEvaluating">
        <span class="loader" *ngIf="isSubmitting || isEvaluating" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
        <span>
          {{ 'action.submit' | translate }}
        </span>
      </button>
    }
  </div>
</div>
