<div class="surgery-cards mb-4 mb-lg-5" *ngIf="appointments && appointments.length > 0">
  <div class="surgery-card mb-2" *ngFor="let appointment of appointments; index as i">

    <div class="card" *ngIf="i === 0">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-7">
            <h6 class="h7"><a href="#" class="text-link no-border text-body"  (click)="showSurgeryDetails($event, appointment)">{{ 'pages.default.dashboard.surgery.title' | translate }}</a></h6>
            <p class="mt-auto mb-0">{{ 'pages.default.dashboard.surgery.details_description' | translate }}</p>
          </div>
          <div class="col-5">
            <div class="d-flex flex-column justify-content-between align-items-end h-100">
              <a class="text-link no-border small text-muted" href="#" (click)="showDateDetails($event)">
                <span class="label" *ngIf="appointment.date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
                </span>
                <span class="label" *ngIf="appointment.start_date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
                </span>
                <span class="icon icon-end icon-xs" [inlineSVG]="'/assets/svg/info-16.svg'"></span>
              </a>

              <button class="btn btn-secondary btn-sm" (click)="showSurgeryDetails($event, appointment)">
                <span class="label">{{ 'pages.default.dashboard.surgery.details_link' | translate }}</span>
                <span class="icon-end">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="i > 0">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-7">
            <h6 class="mb-0 h7"><a href="#" class="text-link no-border text-body" (click)="showSurgeryDetails($event, appointment)">{{ 'pages.default.dashboard.surgery.title' | translate }}</a></h6>
          </div>
          <div class="col-5">
            <div class="d-flex">
              <a class="text-link no-border small text-muted m-start-auto" href="#" (click)="showDateDetails($event)">
                <span class="label" *ngIf="appointment.date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
                </span>
                <span class="label" *ngIf="appointment.start_date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
                </span>
                <span class="icon icon-end icon-xs" [inlineSVG]="'/assets/svg/info-16.svg'"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
