<div class="container layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part d-none d-sm-block">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small" *ngIf="homeFlowService.getSidebarTitleKey()">{{
        homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboaring-icon-steps *ngIf="showOnboardingIconSteps" [onBoardingType]="onBoardingType"
        [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">

    <div class="container" *ngIf="!isFormDataLoaded">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="isFormDataLoaded">
      <h3 class="mb-3">{{ 'pages.flow.onboarding_details.your_details' | translate }}</h3>
      <app-onboarding-details-form (onFormInitialized)="onFormInitialized($event)" [mode]="'onboarding'"
        [data]="formComponentData" [validationVisible]="validationVisible"></app-onboarding-details-form>
    </div>

    <div class="mt-4 d-block d-sm-none">
      <hr/>
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="w-100 d-flex flex-column-reverse flex-xs-row justify-content-xs-end">
          <button class="btn btn-lg btn-primary m-start-xs-3 justify-content-center" (click)="formSubmit()"
            [ngClass]="{'loader': isUpdatingPatient}">
            <span class="loader" *ngIf="isUpdatingPatient" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
            <span>{{ 'action.continue' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
