
<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/help-center" label="pages.default.help_center.help_center" labelPrefix="true" />
    </div>

    <div class="container">
      <h5>{{ helpcenterSection?.title }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="articles?.length === 0">
      <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.helpcenter_articles.no_articles_yet' | translate }}</h6>
    </div>

    <div class="row g-4" >
      <div class="col-4 mb-2" *ngFor="let article of articles">
        <a href="" (click)="goToArticleDetail($event, article)" class="card preview-card h-100">
          <div class="card-cover">
            <img *ngIf="article?.thumbnail?.image?.url" [src]="article?.thumbnail?.image?.url + '?width=814&disable=upscale&format=pjpg&auto=webp' " alt="Thumbnail" />

            <div class="cover-meta">
              <div class="meta-category" *ngIf="article?.thumbnail">
                <span [inlineSVG]="'/assets/svg/file-general-24.svg'" *ngIf="article.isArticle" class="rtl-mirrored-inline-svg"></span>
                <span [inlineSVG]="'/assets/svg/file-pdf-24.svg'" *ngIf="article.isDocument"></span>
                <span [inlineSVG]="'/assets/svg/play-24.svg'" *ngIf="article.isMedia" class="rtl-mirrored-inline-svg"></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p class="lead fw-semibold mb-1 e2e-material-title">{{ article.title }}</p>
            <p class="fw-normal mb-0 e2e-material-description">{{ article.description }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
