<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/patient', patientUid, patientPathwayUid, 'faq']" class="btn btn-tertiary">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/arrow-left-24.svg'"></span>
          <span>{{ 'pages.default.faq_detail.back_to_faq' | translate }}</span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    <app-faq-content
      [faq]="faq"
      [category]="category"
      (goToFaq)="goToFaq($event)"
    />
  </div>
</div>
