<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_personal_details.edit_info' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" *ngIf="form" id="form" [ngClass]="{'validation-visible': validationVisible}">

    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>
    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture" />
    </div>

    <div class="form-group">
      <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>
      <input type="text" id="inputPreferredName" class="form-control w-100" [placeholder]="('form.labels.preferred_name' | translate)" formControlName="preferred_name">
    </div>

    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.country' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="countryOptions"
        [bindValue]="'value'"
        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"
        formControlName="country"
        [asFilter]="false"
        [showCheckboxes]="false"
      />
      <app-feedback-field [field]="form.get('country')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"
        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"
        formControlName="language"
        [asFilter]="false"
        [showCheckboxes]="false"
      />
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}" (click)="handleConfirm()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
