<div class="calendar-appointment-compact">
  <div class="modal-content">

    <div class="modal-header border-0 pb-0">
      <h6 class="h7">{{ appointment?.title }}</h6>
      <button type="button" class="btn-close" id="appointment-compact-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
    </div>

    <div class="modal-body">
      <div>
        @if (appointment.start_date || appointment.date) {
          <p class="d-flex align-items-center small font-weight-medium">
            <span [inlineSVG]="'/assets/svg/calendar-outline-24.svg'" class="icon icon-sm m-end-1 text-muted"></span>

            <span class="text-capitalize">{{ (appointment.start_date || appointment.date) | weekday }}</span>,
            {{ (appointment.start_date || appointment.date) | timeZoneDate }}

            @if (appointment.start_date && appointment.end_date) {
              {{ 'modals.appointment.from' | translate }}
              <span [dir]="languageService?.isHebrew ? 'ltr' : ''">
                {{ appointment.start_date | time }} - {{ appointment.end_date | time }}
              </span>
            } @else if (appointment.date) {
              <span class="text-info font-weight-bold m-start-1">
                {{ 'pages.default.calendar.full_day' | translate }}
              </span>
            }
          </p>
        }
      </div>

      @if (appointment?.location?.name) {
        <div class="mt-2 d-flex align-items-center">
          <p class="mb-0">
            <span [inlineSVG]="'/assets/svg/hospital-24.svg'" class="icon icon-sm m-end-1 text-muted"></span>
          </p>

          @if (appointment.location?.url) {
            <a [href]="appointment.location.url" class="text-link" id="calendar-appointment-compact-appointment-location" target="_blank">
              <span class="label">{{ appointment.location?.name }}</span>
              <span class="icon-end" [inlineSVG]="'/assets/svg/external-12.svg'"></span>
            </a>
          } @else {
            <p class="m-0" *ngIf="!appointment.location?.url">{{ appointment.location?.name }}</p>
          }
        </div>
      }

      <hr>

      @if (appointment.invitees.length) {
        <div class="mt-2">
          <p class="extra-small text-muted mb-1">{{ 'modals.appointment.hcps' | translate }}</p>

          <div class="participants">
            @for (invitee of appointment.invitees; track invitee.uid) {
              <div class="item" [ngClass]="{'mt-1': !$first }">
                <app-avatar [profilePicture]="invitee?.profile_picture"></app-avatar>

                <span class="m-0">
                  {{ invitee?.getFullName() }}
                </span>
              </div>
            }
          </div>
        </div>
      }

    </div>

    <div class="modal-footer border-0 pt-0 mt-1">
      <button class="btn btn-sm btn-primary" (click)="viewDetailsHandler($event)">
        <span class="label">{{ 'modals.appointment.view_details' | translate }}</span>
      </button>
    </div>
  </div>
</div>
