import { Component, OnInit } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent extends Toast implements OnInit {
  public icon?: string = null;

  public containerClasses: string[] = ['info-box'];
  public iconClasses: string[] = ['info-box-icon'];
  public titleClasses: string[] = ['info-box-title'];
  public messageClasses: string[] = [];

  ngOnInit(): void {
    switch(this.toastPackage.toastType) {
      case 'toast-warning':
        this.containerClasses.push('info-box-warning');
        this.icon = '/assets/svg/warning-24.svg';
      break;
      case 'toast-error':
        this.containerClasses.push('info-box-danger');
        this.icon = '/assets/svg/alert-16.svg';
      break;
      case 'toast-success':
        this.containerClasses.push('info-box-success');
        this.icon = '/assets/svg/success-24.svg';
      break;
      case 'toast-info':
      default:
        this.icon = '/assets/svg/info-circle-24.svg';
    }

    if (!this.title) {
      this.messageClasses.push('info-box-title');
    }

    this.titleClasses.push(this.options?.titleClass);
    this.messageClasses.push(this.options?.messageClass);
  }
}
