<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/dashboard" label="action.back_to_overview" />
    </div>

    <div class="container">
      <h5>{{ 'pages.default.hospital.hospital' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="hospital-block">
      <div class="hospital-content">

        <div class="row justify-content-between">
          <div class="col col-auto">
            <div *ngIf="hospital?.logo?.url" class="logo" [ngStyle]="{'background-image': 'url(' + hospital.logo.url + '?width=360&disable=upscale&format=pjpg&auto=webp' + ')'}"></div>

            <h6 class="name mb-3" id="hospital_name">{{hospital?.name}}</h6>

            <p class="text-muted mb-3">{{ 'pages.default.hospital.contact_details' | translate }}</p>

            <div class="details">
              <div class="item">
                <div class="icon" [inlineSVG]="'/assets/svg/building-outline-24.svg'"></div>
                <div class="value">
                  <a href="{{ hospital?.google_maps_uri}}" target="_blank" id="link_hospital_location" rel="noopener" [ngSwitch]="hospital?.address.country">
                    <span *ngSwitchCase="'FR'">
                      <span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>&nbsp;<span id="hospital_location_address_street">{{hospital?.address.street }}</span>,&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>
                    </span>
                    <span *ngSwitchCase="'GB'">
                      <span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>&nbsp;<span id="hospital_location_address_street">{{hospital?.address.street }}</span>,&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>
                    </span>
                    <span *ngSwitchDefault>
                      <span id="hospital_location_address_street">{{hospital?.address.street }}</span>&nbsp;<span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>,&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>
                    </span>
                  </a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.phone_number">
                <div class="icon" [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'"></div>
                <div class="value">
                  <p class="mb-0"><span id="hospital_phone_code">{{ getDialCode(hospital?.phone_number.code)?.label}}</span> <span id="hospital_phone_number">{{ hospital?.phone_number.number }}</span></p>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.email">
                <div class="icon" [inlineSVG]="'/assets/svg/envelope-outline-24.svg'"></div>
                <div class="value">
                  <a href="mailto:{{ hospital?.email }}" target="_blank" id="link_hospital_email" rel="noopener">{{ hospital?.email }}</a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.site">
                <div class="icon" [inlineSVG]="'/assets/svg/language-24.svg'"></div>
                <div class="value">
                  <a href="{{ hospital?.site.url }}" target="_blank" id="link_hospital_email" rel="noopener">{{ hospital?.site.name }}</a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.open_24_7 || hospital?.opening_hours">
                <div class="icon mb-auto" [inlineSVG]="'/assets/svg/calendar-outline-24.svg'"></div>
                  <div class="value" *ngIf="hospital?.open_24_7">
                    <p class="mb-0">{{ 'pages.default.hospital.open_24_7' | translate }}</p>
                  </div>

                <div class="value" *ngIf="!hospital?.open_24_7">
                  <a href="" target="_blank" class="link-list-action" (click)="toggleOpeningHours($event)" rel="noopener">
                    <span class="align-middle">{{ 'pages.default.hospital.opening_hours' | translate }}</span>
                    <span class="align-middle" [inlineSVG]="'/assets/svg/chevron-down-24.svg'" *ngIf="!openingHoursVisible"></span>
                    <span class="align-middle" [inlineSVG]="'/assets/svg/chevron-up-24.svg'" *ngIf="openingHoursVisible"></span>
                  </a>
                  <div class="link-list-table" *ngIf="openingHoursVisible">
                    <table cellpadding="0" cellspacing="0">
                      <tr *ngFor="let item of hospital?.opening_hours; index as index">
                        <td>{{ item.day }}</td>
                        <td *ngIf="isClosed(index)">{{ 'pages.default.hospital.closed' | translate }}</td>
                        <td *ngIf="isOpen24Hours(index)">{{ 'pages.default.hospital.open_24' | translate }}</td>
                        <td *ngIf="isCustom(index) && !isLocaleHebrew()">{{ item.hours.open.label }} - {{ item.hours.closed.label}}</td>
                        <td *ngIf="isCustom(index) && isLocaleHebrew()"> {{ item.hours.closed.label}} - {{ item.hours.open.label }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col col-pictures count-pictures-{{hospital?.images?.length}}">
            <div class="row justify-content-end pictures" id="hospital_images">
              <ng-container *ngFor="let image of hospital?.images">
                <div class="col" [ngClass]="(hospital?.images?.length >2) ? 'col-6' : 'col-12'">
                  <div class="item" [ngStyle]="{ 'background-image': 'url(' + image?.url + '?width=768&disable=upscale&format=pjpg&auto=webp' + ')' }"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <ng-container *ngIf="hospital?.description">
          <hr class="my-5"/>

          <p class="text-muted mb-1">{{ 'pages.default.hospital.about_this_hospital' | translate }}</p>


          <div [innerHtml]="hospital?.description" class="description"></div>
        </ng-container>

      </div>

    </div>
  </div>
</div>
